interface ApiInterface {
  hostname: string;
  hosttype: object;
  mmt_backend_hostname: string;
  endpoints: {
    [key: string]: string;
  };
}

const ApiConfig: ApiInterface = {
  hostname: process.env.REACT_APP_API_URL ?? "",
  hosttype: {
    mmt: "mmt",
  },
  mmt_backend_hostname: process.env.REACT_APP_MMT_API_BASE_URL ?? "",
  endpoints: {
    //AUTHENTICATION
    register_client: "v1/authentication/register/client-user",
    login_client: "v1/authentication/client/login",
    authenticate_user: "v1/authentication/client/authenticate",
    reset_password: "v1/authentication/reset-password",
    forgot_password: "v1/authentication/forgot-password",
    resend_otp: "v1/authentication/resend-otp",
    // vendor side
    register_vendor: "v1/authentication/register/vendor-user",
    login_vendor: "v1/authentication/vendor/login",

    //WORKSPACE
    create_workspace: "v1/workspace/create",
    get_workspaces: "/v1/workspace/get-workspaces",
    get_workspace_users: "/v1/workspace/get-workspace",
    add_user_to_workspace: "/v1/workspace/add-user",
    get_workspace_details: "/v1/workspace/get-workspace",
    get_workspace_line_items_milestones: "/v1/workspace", //:workspaceId/get-line-items

    //PROJECT
    get_workspace_projects: "/v1/project/get-workspace-projects",
    create_project: "/v1/project/create",
    get_project: "/v1/project/get",

    //PO
    create_po: "/v1/po/create",
    get_project_pos: "/v1/po/get-project-pos",
    get_po_details: "/v1/po/get",
    get_po_users: "/v1/po/get-po-users",
    update_po: "/v1/po/update-po",
    delete_po: "/v1/po/delete-po",
    get_vendor_pos: "/v1/po/get-vendors-po",
    get_po_report: "/v1/report/get-report",

    //LINE ITEM
    get_po_line_items_milstones: "/v1/line-item/po-line-items",
    add_line_item: "/v1/line-item/create",
    edit_line_item: "/v1/line-item/update",
    delete_line_item: "/v1/line-item/delete",
    send_line_item_custom_email: "/v1/line-item/send-email",

    //MILESTONE
    add_milestone: "/v1/milestone/create",
    update_milestone: "/v1/milestone/update",
    upload_mil_comment_file: "/v1/milestone/upload-file",
    delete_milestone: "/v1/milestone/delete",
    get_milestone_comments: "/v1/comment",
    download_drawings: "v1/milestone/drawings",
    create_mappings: "v1/component-milestone/create",
    check_mapping: "v1/component-milestone/get-component-milestone-mapping",

    //COMPONENT
    add_component: "/v1/component/create",
    update_component: "/v1/component/update",
    delete_component: "/v1/component/delete",

    // component-milestone
    get_milestone_components: "v1/component-milestone/get-milestone-components",
    update_milestone_component: "v1/component-milestone/update-milestone-component",
    update_multiple_component_stage: "v1/component-milestone/update-multiple-component-stage",
    add_component_milestone_comment: "v1/component-milestone/add-component-milestone-comment",

    //DATA IMPORT
    data_import: "/v1/data-import/upload",
    line_item_import: "/v1/data-import/upload-line-items",

    //CATEGORIES
    categories: "/v1/mappings/categories",
    bulk_categories: "/v1/data-import/upload-category",

    //ACIVITY_LOGS
    get_activity_logs: "/v1/activity-log/export-activity-log",

    //FILE
    upload_files: "/v1/file/uploads",
  },
};
export default ApiConfig;
